<template>
  <b-row>
    <b-col cols="12">
      <table class="table">
        <thead class="thead-light">
        <tr class="row">
          <th scope="col" class="col-md-3"><b>Device SN</b></th>
          <th scope="col" class="col-md-3"><b>First Log In Date</b></th>
          <th scope="col" class="col-md-3"><b>Last Log In Date</b></th>
          <th scope="col" class="col-md-3"><b>Associated Programs</b></th>
        </tr>
        </thead>
        <tbody>
        <tr class="row" v-for="(device, index) in program_devices" :key="index">
          <td class="col-md-3">
            {{ device.serial_number }}</td>
          <td class="col-md-3">{{ normalizeDate(device.first_login_date) }}</td>
          <td class="col-md-3">{{ normalizeDate(device.last_login_date) }}</td>
          <td class="col-md-3">
            <b-button
              size="sm"
              variant="info"
              @click="showDeviceAssociatedProgram(device.id)">
              View
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
    </b-col>
    <b-modal :id='`modal-associated-programs-${this.program_uuid}`' ok-only title="Associated programs" centered size='lg'>
      <b-col cols="12">
        <table class="table">
          <thead class="thead-light">
          <tr class="row">
            <th scope="col" class="col-md-4"><b>Program UUID</b></th>
            <th scope="col" class="col-md-4"><b>Last Log In Date</b></th>
            <th scope="col" class="col-md-4"><b>Program Status</b></th>
          </tr>
          </thead>
          <tbody>
          <tr class="row" v-for="(device, index) in associated_programs" :key="index">
            <td class="col-md-4">
              <router-link target="_blank" :to="`/ops/search-member?program_uuid=${device.program_uuid}`">
                      {{ device.program_uuid }}
              </router-link>
            </td>
            <td class="col-md-4">{{ normalizeDate(device.last_track_time) }}</td>
            <td class="col-md-4">{{ device.program_closed_at ? 'Inactive' : 'Active' }}</td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-modal>
  </b-row>
</template>


<script>
import axios from 'axios';
import moment from 'moment/moment';
import { isNullOrEmpty } from '@/helpers';

export default {
  name: 'MultiModeTab',
  props: {
    program_uuid: {
      type: String,
      required: true,
    },
    program_devices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      associated_programs: [],
      associated_programs_fetched: false,
      associated_programs_fetched_device_id: null,
    };
  },
  methods: {
    showDeviceAssociatedProgram(deviceID) {
      // Open modal
      this.$bvModal.show(`modal-associated-programs-${this.program_uuid}`);

      this.getAssociatedPrograms(deviceID);
    },
    getAssociatedPrograms(deviceID) {
      // Avoid fetching associated programs if already fetched for the same device
      if (this.associated_programs_fetched && this.associated_programs_fetched_device_id === deviceID) {
        return;
      }
      // Clear associated programs to the previous device
      this.associated_programs = [];
      // Request associated programs
      axios.get(`/v1/member-search/programs/${this.program_uuid}/devices/${deviceID}/associated-programs`)
        .then(response => {
          // Filter out the record where program_uuid equals this.program_uuid
          this.associated_programs = response.data.filter(program => program.program_uuid !== this.program_uuid);
          this.associated_programs_fetched = true;
          this.associated_programs_fetched_device_id = deviceID;
        })
        .catch(() => {
          this.$noty.error('Error while getting device associated programs');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    normalizeDate(date) {
      if (isNullOrEmpty(date)) return '-';
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
  },
};

</script>
