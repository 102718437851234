<script>
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';
import Orders from '@/components/MemberSearch/Orders/Orders.vue';
import Prescriptions from '@/components/MemberSearch/Prescriptions/Prescriptions.vue';
import ClinicalDetails from '@/views/OpsSearchProgram/partials/ClinicalDetails.vue';
import DTTab from '@/views/OpsSearchProgram/partials/DTTab.vue';
import BloomPodTab from '@/views/OpsSearchProgram/partials/BloomPodTab.vue';
import MemberAppTab from '@/views/OpsSearchProgram/partials/MemberAppTab.vue';
import EcosystemDetails from '@/views/OpsSearchProgram/partials/EcosystemDetails.vue';
import ReferralsDetails from '@/views/OpsSearchProgram/partials/ReferralsDetails.vue';
import {
  UNIT_DPT, UNIT_SWORD, UNIT_BLOOM, UNIT_MOVE, UNIT_MIND,
} from '@/scripts/constants';
import * as R from 'ramda';
import ProgramRecordsTab from './ProgramRecordsTab.vue';
import ProgramStatesModal from './ProgramStatesModal.vue';
import ChatTab from './ChatTab.vue';
import SMSCommsTab from './SMSCommsTab.vue';
import MultiMode from './MultiMode.vue';

const formIsVideoCall = form => {
  if (form.mainCategory === 'onboarding' && form.subCategory === 'video_call') {
    return true;
  }
  if (form.filters.includes('onboarding_call')) {
    return true;
  }
  return false;
};
export default {
  name: 'ProgramDetails',
  components: {
    Orders,
    ProgramRecordsTab,
    ClinicalDetails,
    Prescriptions,
    DTTab,
    BloomPodTab,
    MemberAppTab,
    EcosystemDetails,
    ReferralsDetails,
    ProgramStatesModal,
    ChatTab,
    SMSCommsTab,
    MultiMode,
  },
  props: {
    account_uuid: {
      type: String,
      required: true,
    },
    client_id: {
      type: Number,
    },
    geographical_state: {
      type: String,
    },
    is_account_deleted: {
      type: Boolean,
    },
    program: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
    },
  },
  data() {
    return {
      tabIndex: 0,
      clinicalForms: {
        items: [],
        fields: [
          { key: 'name', label: 'Name' },
          { key: 'status', label: 'Status' },
          { key: 'category', label: 'Category' },
          { key: 'submittedAt', label: 'Submitted at' },
        ],
      },
      enrollment: null,
      programDevices: [],
      openClinicalTab: false,
      openEcosystemTab: false,
      openReferralsTab: false,
      openDTTab: false,
      openMemberAppTab: false,
      openChatTab: false,
      openBloompodTab: false,
      openSMSCommsTab: false,
      openMultiModeTab: false,
    };
  },
  computed: {
    ...mapGetters({
      getRescheduleURL: 'Core/getRescheduleURL',
      getEmergencyCoverageURL: 'Core/getEmergencyCoverageURL',
    }),
    formatDateEnrolledAt() {
      return moment(this.program.general_information.enrolled_at).format('YYYY-MM-DD');
    },
    formatOnboardingCallType() {
      switch (this.program.onboarding_call.call_type) {
        case 'video_call':
          return 'Video call';
        case 'phone_call':
          return 'Phone call';
        default:
          return '-';
      }
    },
    getQuantityOfClinicalForms() {
      return this.clinicalForms.items.length;
    },
    formatOnboardingCallStatus() {
      let callStatus = '-';
      if (this.clinicalForms.items.length < 1) {
        return callStatus;
      }

      this.clinicalForms.items.forEach(form => {
        if (formIsVideoCall(form)) {
          callStatus = form.status;
        }
      });
      return callStatus;
    },
    tabIcon() {
      let progressIcon = 'circle';
      switch (this.program.status) {
        case 'ongoing':
          progressIcon = 'activity';
          break;
        case 'completed':
          progressIcon = 'x-circle';
          break;
        default:
          progressIcon = 'circle';
      }

      switch (this.program.unit) {
        case 'bloom':
          return { icon: 'gender-female', title: 'Bloom', progressIcon };
        case 'dpt':
        case 'sword':
          return { icon: 'tablet-landscape', title: 'DPT', progressIcon };
        case 'move':
          return { icon: 'play-circle', title: 'Move', progressIcon };
        case 'mind':
          return { icon: 'bullseye', title: 'Mind', progressIcon };
        default:
          return { icon: 'bug', title: 'Error', progressIcon };
      }
    },
    showDTTab() {
      return [ UNIT_SWORD, UNIT_DPT ].includes(this.program.unit);
    },
    showBloomPodTab() {
      return [ UNIT_BLOOM ].includes(this.program.unit);
    },
    showOrderTab() {
      return [ UNIT_SWORD, UNIT_DPT, UNIT_BLOOM, UNIT_MOVE ].includes(this.program.unit);
    },
    showChatTab() {
      return [ UNIT_SWORD, UNIT_DPT, UNIT_BLOOM, UNIT_MOVE, UNIT_MIND ].includes(this.program.unit);
    },
    showSMSCommsTab() {
      return [ UNIT_SWORD, UNIT_DPT, UNIT_BLOOM, UNIT_MOVE, UNIT_MIND ].includes(this.program.unit);
    },
    showMultiModeTab() {
      return this.programDevices.length > 0;
    },
    emergencyCoverageButtonCanBeDisplayed() {
      return (this.program.onboarding_call.appointment_uuid
        && this.program.general_information.origin
        && this.program.onboarding_call.status !== 'SUBMITTED');
    },
    rescheduleButtonCanBeDisplayed() {
      return (!this.program.onboarding_call.locked_at
        && !this.is_account_deleted);
    },
  },
  methods: {
    unitName(unit) {
      if (unit === 'sword') {
        return 'DPT';
      }
      return unit;
    },
    openStateMachineModal() {
      this.$bvModal.show(`modal-state-machine-${this.program.program_id}`);
    },
    closeStateMachineModal() {
      this.$bvModal.hide(`modal-state-machine-${this.program.program_id}`);
    },
    openClinicalFormsModal() {
      this.$bvModal.show(`modal-clinical-forms-${this.program.general_information.uuid}`);
    },
    loadOrders() {
      this.$refs.orderRef.loadOrders();
    },
    timestampToDate(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD');
    },
    timestampToDateTime(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
    },
    toggleClinicalTab() {
      this.openClinicalTab = true;
    },
    toggleEcosystemTab() {
      this.openEcosystemTab = true;
    },
    toggleReferralsTab() {
      this.openReferralsTab = true;
    },
    toggleDTTab() {
      this.openDTTab = true;
    },
    toggleMemberAppTab() {
      this.openMemberAppTab = true;
    },
    toggleChatTab() {
      this.openChatTab = true;
    },
    toogleBloomPodTab() {
      this.openBloompodTab = true;
    },
    toggleSMSCommsTab() {
      this.openSMSCommsTab = true;
    },
    toggleMultiModeTab() {
      this.openMultiModeTab = true;
    },
    fetchClinicalForms() {
      return axios.get(`/v1/member-search/${this.program.general_information.uuid}/forms`)
        .then(response => {
          this.clinicalForms.items = response.data;
          const { modal, program_uuid: programUUID } = this.$route.query;
          if (modal === 'state-machine' && programUUID === this.program.uuid) {
            this.openStateMachineModal();
          }
        })
        .catch(err => {
          if (R.path([ 'response', 'data', 'message' ], err)) {
            this.$noty.error(err.response.data.message);
          } else {
            this.$noty.error(R.propOr('Failed to fetch clinical forms', 'message', err));
          }
        });
    },
    async fetchEnrollment() {
      await axios.get(`v1/onboarding/enrollments/${this.program.uuid}`)
        .then(({ data }) => {
          this.enrollment = data.enrollment;
        }).catch(e => {
          console.error('Error fetching enrollment', e);
        });
    },
    onTabChange() {
      switch (this.$route.query.tab) {
        case 'clinical':
          this.toggleClinicalTab();
          break;
        case 'member_app':
          this.toggleMemberAppTab();
          break;
        case 'dt':
          this.toggleDTTab();
          break;
        case 'orders':
          this.loadOrders();
          break;
        case 'ecosystem':
          this.toggleEcosystemTab();
          break;
        case 'bloom_pod':
          this.toogleBloompodTab();
          break;
        case 'referrals':
          this.toggleReferralsTab();
          break;
        case 'chat':
          this.toggleChatTab();
          break;
        case 'sms':
          this.toggleSMSCommsTab();
          break;
        case 'multi_mode':
          this.toggleMultiModeTab();
          break;
        default:
          // no-op
      }
    },
    async fetchMultimodeInfo() {
      await axios.get(`/v1/member-search/programs/${this.program.uuid}/multi-mode`)
        .then(response => {
          this.programDevices = response.data;
        })
        .catch(() => {
          this.$noty.error('Error while getting multi-mode information');
        });
    },
  },
  mounted() {
    this.fetchClinicalForms();
    this.fetchEnrollment();
    this.fetchMultimodeInfo();
  },
  watch: {
    tabIndex() {
      this.onTabChange();
    },
  },
};

</script>
<template>
  <b-tab>
    <template #title>
      <b-icon font-scale="1" class="mr-2" :icon="tabIcon.icon" :title="tabIcon.title"></b-icon>
      <span class="mr-2">{{ timestampToDate(program.enrolled_at) }}</span>
    </template>
    <b-card-text>
      <b-tabs v-model="tabIndex" content-class="mt-3">

        <b-tab title="General" :active="$route.query.tab === 'general'">
          <b-row>
            <b-col cols="12" lg="6">
                <ul class="list-unstyled mb-0">
                  <li><b>Client: </b>
                    <router-link
                      v-if="program.client.id" target="_blank" :to="`/onboarding/client/edit/${program.client.id}`
                    ">
                      {{ program.client.name }} ({{ program.client.id}})
                  </router-link>
                  <span v-else>-</span>
                  </li>
                  <li><b>Unit: </b>{{ unitName(program.unit) }}</li>
                  <li><b>Program UUID: </b><small>{{ program.general_information.uuid }}</small></li>
                  <li><b>Program ID: </b>{{ program.general_information.program_id }}</li>
                  <li>
                    <b>State: </b>{{ program.general_information.state || " - " }}
                    <b-badge role="button" @click="openStateMachineModal" variant="info">see details</b-badge>
                  </li>
                  <li><b>Enrolled at: </b>{{ enrollment?.business_enrolled_at ? timestampToDateTime(enrollment.business_enrolled_at) : '' }}</li>
                  <li><b>Screened at: </b>{{ program?.enrolled_at ? timestampToDateTime(program.enrolled_at) : '' }}</li>
                  <li><b>Multi mode: </b>{{ program.is_multimode }}</li>
                </ul>
            </b-col>
            <b-col cols="12" lg="6">
              <ul class="list-unstyled mb-0">
                <li><b>Call Status: </b>{{ formatOnboardingCallStatus }}</li>
                <li><b>Call Type: </b> {{ formatOnboardingCallType }} </li>
                <li><b>Call Date: </b>{{ timestampToDateTime(program.onboarding_call.date) }}
                  <b-badge v-if="rescheduleButtonCanBeDisplayed" target="_blank" title="Reschedule appointment"
                    :href="getRescheduleURL(program.onboarding_call.appointment_uuid, client)" role="button"
                    class="ml-3" variant="info">reschedule</b-badge>
                </li>
                <li><b>Call url: </b><a target="_blank" :href="program.onboarding_call.url">link to call</a>
                  <b-badge v-if="emergencyCoverageButtonCanBeDisplayed" role="button" variant="warning" class="ml-3"
                           :href="getEmergencyCoverageURL(program.onboarding_call.appointment_uuid, client)">
                    emergency coverage
                  </b-badge>
                </li>
                <li>
                  <b>Call PT: </b>
                  <router-link v-if="program.onboarding_call.therapist.id"
                               target="_blank"
                               :to="{ name: 'ProfessionalEditDeprecated', params: { id: program.onboarding_call.therapist.id } }">
                    {{ program.onboarding_call.therapist.name }} ({{ program.onboarding_call.therapist.id }})
                  </router-link>
                  <span v-else>-</span>
                </li>
                <li>
                  <b>Clinical forms: </b> {{getQuantityOfClinicalForms}}
                  <b-badge v-if="getQuantityOfClinicalForms" role="button" variant="warning" class="ml-3" @click="openClinicalFormsModal">
                    details
                  </b-badge>
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Journey" :active="$route.query.tab === 'journey'" v-if="false"></b-tab>

        <b-tab title="Multi Mode" :active="$route.query.tab === 'multi_mode'" @click="toggleMultiModeTab" v-if="showMultiModeTab">
          <div v-if="openMultiModeTab">
            <MultiMode :program_uuid="this.program.general_information.uuid" :program_devices="this.programDevices"></MultiMode>
          </div>
        </b-tab>

        <b-tab @click="toggleClinicalTab" title="Clinical" :active="$route.query.tab === 'clinical'">
          <div v-if="openClinicalTab">
            <ClinicalDetails :program-i-d="program.general_information.program_id"
                             :program-u-u-i-d="program.general_information.uuid"
                             :account-u-u-i-d="account_uuid"
                             :unit="program.unit"
                             :isAccountDeleted="is_account_deleted">
            </ClinicalDetails>
          </div>
        </b-tab>

        <b-tab title="Member app" :active="$route.query.tab === 'member_app'" @click="toggleMemberAppTab" >
          <div v-if="openMemberAppTab">
            <MemberAppTab :account_uuid="account_uuid" :program="this.program"/>
          </div>
        </b-tab>

        <b-tab title="DT" :active="$route.query.tab === 'dt'" @click="toggleDTTab" v-if="showDTTab" >
          <div v-if="openDTTab">
            <DTTab :account_uuid="account_uuid" :program="this.program"/>
          </div>
        </b-tab>

        <b-tab title="Bloom pod" :active="$route.query.tab === 'bloom_pod'" @click="toogleBloomPodTab" v-if="showBloomPodTab">
          <div v-if="openBloompodTab">
            <BloomPodTab :program_uuid="this.program.general_information.uuid"/>
          </div>
        </b-tab>

        <b-tab title="Orders" :active="$route.query.tab === 'orders'" @click="loadOrders" v-if="showOrderTab" >
          <div class="patient_search_orders_holder orders-list-holder">
            <Orders ref="orderRef"
              :programUUID="program.general_information.uuid"
              :clientID="this.client_id"
              :countryStateCode="this.geographical_state"
              :patientId="program.general_information.program_id"
              :therapy="program.kit"
              :isAccountDeleted="is_account_deleted"/>
          </div>
        </b-tab>

        <Prescriptions :active="$route.query.tab === 'prescriptions'" :programUUID="program.general_information.uuid"/>

        <ProgramRecordsTab
          title="Records"
          :active="$route.query.tab === 'records'"
          :clinicalFormsItems="this.clinicalForms.items"
          :accountUUID="this.account_uuid"
          :program="this.program" />

        <b-tab title="Ecosystem" :active="$route.query.tab === 'ecosystem'" @click="toggleEcosystemTab">
          <div v-if="openEcosystemTab">
            <EcosystemDetails :programUUID="program.general_information.uuid"></EcosystemDetails>
          </div>
        </b-tab>

        <b-tab title="Referrals" :active="$route.query.tab === 'referrals'" @click="toggleReferralsTab">
          <div v-if="openReferralsTab">
            <ReferralsDetails :programUUID="program.general_information.uuid"></ReferralsDetails>
          </div>
        </b-tab>

        <b-tab title="Chat" :active="$route.query.tab === 'chat'" @click="toggleChatTab" v-if="showChatTab">
          <div v-if="openChatTab">
            <ChatTab :program_is_active="program.is_active" :unit ="program.unit"
              :account_uuid="account_uuid" :program_uuid="program.general_information.uuid"/>
          </div>
        </b-tab>

        <b-tab title="SMS" :active="$route.query.tab === 'sms'" @click="toggleSMSCommsTab" v-if="showSMSCommsTab">
          <div v-if="openSMSCommsTab">
            <SMSCommsTab :program_is_active="program.is_active" :unit ="program.unit"
              :account_uuid="account_uuid" :program_uuid="program.general_information.uuid"/>
          </div>
        </b-tab>

      </b-tabs>
    </b-card-text>
    <ProgramStatesModal @hideModal="closeStateMachineModal" :program="this.program"></ProgramStatesModal>

    <b-modal :id='`modal-clinical-forms-${program.general_information.uuid}`' size='xl' title='Clinical forms'>
      <b-table :items="clinicalForms.items" :fields="clinicalForms.fields" bordered striped show-empty>
        <template #cell(name)="row">
          <ul class="list-unstyled">
            <li><b>form_uuid:</b> {{row.item.id}}</li>
            <li><b>key:</b> {{row.item.key}}</li>
            <li><b>name:</b> {{row.item.name}}</li>
          </ul>
        </template>
        <template #cell(category)="row">
          <ul class="list-unstyled">
            <li><b>main_category:</b> {{row.item.mainCategory}}</li>
            <li><b>sub_category:</b> {{row.item.subCategory}}</li>
            <li><b>filters:</b> {{row.item.filters}}</li>
          </ul>
        </template>
        <template #empty><div class="text-center my-2"><strong>No data to show</strong></div></template>
      </b-table>
    </b-modal>
  </b-tab>
</template>
